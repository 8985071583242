<template>
  <div class="search">
    <!-- 商品栏 -->
    <div class="show-banner">
      <!-- 商品盒子 -->
      <el-row class="showboxs row-bg"
              type="flex"
              justify="space-between">
        <el-col :span="6"
                class="goodbox"
                v-for="(hotItem, index) in hotDeals"
                :key="index"
                @click.native="goodDeatail(hotItem)">
          <!-- <div class="goodLike"
                 @click="likeWay()"></div> -->
          <!-- 裁剪区域 -->
          <div class="yuan">
            <div class="yuanLeft"></div>
            <div class="xian"></div>
            <div class="yuanRight"></div>
          </div>
          <!-- 商品图片 -->
          <div class="goodImg">
            <img :src="hotItem.product_image_url" />
          </div>
          <!-- 商品标题 -->
          <div class="goodName">{{hotItem.product_title}}</div>
          <!-- 商品价格 -->
          <div class="goodPrice">
            <div class="leftPrice">
              <del class="oldPrice">
                <span v-if="hotItem.site === 'US'">$</span>
                <span v-if="hotItem.site === 'JP'">￥</span>
                <span v-if="hotItem.site === 'UK'">￡</span>
                <span v-if="hotItem.site === 'CA' || hotItem.site === 'MX' || hotItem.site === 'DE' ||hotItem.site === 'FR' || hotItem.site === 'IT' || hotItem.site === 'ES'|| hotItem.site === 'NL' ">€</span>
                {{hotItem.sale_price}}
              </del>
            </div>
            <div class="newPrice">
              <span v-if="hotItem.site === 'US'">$</span>
              <span v-if="hotItem.site === 'JP'">￥</span>
              <span v-if="hotItem.site === 'UK'">￡</span>
              <span v-if="hotItem.site === 'CA' || hotItem.site === 'MX' || hotItem.site === 'DE' ||hotItem.site === 'FR' || hotItem.site === 'IT' || hotItem.site === 'ES'|| hotItem.site === 'NL' ">€</span>
              {{hotItem.deal_price}}
            </div>
          </div>
          <div class="buyGood"
               v-if="goodBool">Apply Now</div>
          <div class="buyGood"
               v-if="!goodBool">Earn {{hotItem.reward_integral}} Points</div>
        </el-col>
        <div class="after"></div>
      </el-row>
    </div>
    <!-- 分页 -->
    <el-pagination @size-change="handleSizeChange"
                   @current-change="handleCurrentChange"
                   :current-page.sync="currentPage"
                   :page-sizes="[10, 20, 50, 100]"
                   :page-size="pagSize"
                   layout="prev,sizes, pager, next, jumper"
                   :total="pagTotal">
    </el-pagination>
  </div>
</template>

<script>
// 引入接口
// 获取折扣商品详情
import { searchGood } from '../../api/buyerFront'
import { returnFloat } from '../../utils/num'
export default {
  data () {
    return {
      hotDeals: [],
      currentPage: 1,
      pagTotal: 0,
      pagSize: 10,
      goodBool: true
    }
  },
  created () {
    // console.log('this.$route ==>', this.$route)
    if (this.$route.query.inputText === '') {
      this.$route.query.inputText = null
    }
    // 获取折扣商品详情
    searchGood({
      name: this.$route.query.inputText,
      size: this.pagSize,
      page: this.currentPage,
      site: this.$store.state.countrySplit,
      type: this.$route.query.goodUrl
    }).then(result => {
      if (result.data.code === 200) {
        // console.log(result)
        this.hotDeals = result.data.data.rows
        this.pagTotal = result.data.data.total
        if (this.$route.query.goodUrl === '/freebies/product/point') {
          this.goodBool = false
          // ('aaa')
        }
        this.hotDeals.map(v => {
          v.product_image_url = v.product_image_url.split('"')[1]
          v.deal_price = returnFloat(v.deal_price)
          v.sale_price = returnFloat(v.sale_price)
        })
        // this.goodBool = true
      }
    }).catch(err => {
      return err
    })
  },
  methods: {
    // 分页
    handleSizeChange (val) {
      // (`每页 ce${ val } 条`)
      this.pagSize = val
      // 获取折扣商品详情
      searchGood({
        name: '',
        size: val,
        page: this.currentPage,
        site: this.$store.state.countrySplit,
        type: this.$route.query.goodUrl
      }).then(result => {
        if (result.data.code === 200) {
          this.hotDeals = result.data.data.rows
          this.pagTotal = result.data.data.total
          this.hotDeals.map(v => {
            v.product_image_url = v.product_image_url.split('"')[1]
            v.deal_price = returnFloat(v.deal_price)
            v.sale_price = returnFloat(v.sale_price)
          })
          // (result.data.data.rows[0].product_image_url)
        }
      }).catch(err => {
        return err
      })
    },
    handleCurrentChange (val) {
      // (val, this.currentPage)
      searchGood({
        name: '',
        size: this.pagSize,
        page: val,
        site: this.$store.state.countrySplit,
        type: this.$route.query.goodUrl
      }).then(result => {
        if (result.data.code === 200) {
          this.hotDeals = result.data.data.rows
          this.pagTotal = result.data.data.total
          this.hotDeals.map(v => {
            v.product_image_url = v.product_image_url.split('"')[1]
            v.deal_price = returnFloat(v.deal_price)
            v.sale_price = returnFloat(v.sale_price)
          })
          // (result.data.data.rows[0].product_image_url)
        }
      }).catch(err => {
        return err
      })
    },
    // 跳转详情
    goodDeatail (item) {
      // console.log(this.$route.query.goodUrl)
      if (this.$route.query.goodUrl === '/product/search/deal') {
        this.$router.push({ path: '/F_dealDetails', query: { goodUrl: item.url, goodId: item.product_id } })
      } else if (this.$route.query.goodUrl === '/product/search/all') {
        if (item.url.split('/')[1] === 'deal') {
          this.$router.push({ path: '/F_dealDetails', query: { goodUrl: item.url, goodId: item.product_id } })
        } else {
          this.$router.push({ path: '/F_freeDetails', query: { goodUrl: item.url, goodId: item.product_id } })
        }
      } else {
        // console.log('111')
        this.$router.push({ path: '/F_freeDetails', query: { goodUrl: item.url, goodId: item.product_id } })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.search {
  padding: 20px;
  // 商品栏
  .show-banner {
    text-align: left;
    width: 100%;
    margin-top: 10px;

    .topBox {
      border-bottom: 1px solid #ccc;
    }

    .title {
      display: inline-block;
      font-size: 32px;
      font-weight: bold;
      padding-bottom: 5px;
      border-bottom: 3px solid #ff9933;
    }

    .showboxs {
      // float: left;
      margin-top: 20px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap; // flex-direction: column;

      .after {
        content: "";
        flex: auto;

        // &::after {
        //   display: block;
        //   content: "";
        //   width: 30%;
        //   height: 0px;
        // }
      }

      .goodbox {
        width: 100%;
        position: relative;
        width: 15%;
        background: white;
        height: 300px;
        padding: 10px;
        box-sizing: border-box;
        margin: 10px 30px 10px 0;
        cursor: pointer;

        &:nth-child(6n) {
          margin-right: 0;
        }

        .goodLike {
          pointer-events: auto;
          font-family: "iconfont" !important;
          font-size: 25px;
          font-style: normal;
          -webkit-font-smoothing: antialiased;
          display: inline;

          &::before {
            content: "\e68d";
          }

          &:hover {
            color: red;
          }
        }

        .yuan {
          position: absolute;
          left: 0;
          top: 165px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          .yuanLeft {
            transform: translateX(-15px);
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background: #f2f2f2;
          }

          .xian {
            border: 2px dashed #f2f2f2;
            width: 65%;
            height: 0;
          }

          .yuanRight {
            transform: translateX(15px);
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background: #f2f2f2;
          }
        }

        .goodImg {
          width: 100%;
          height: 150px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 40px;

          img {
            width: 150px;
            height: 120px;
          }
        }

        .goodName {
          padding: 0 15px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin: 10px 0;
        }

        .goodLogistics {
          padding: 10px 15px;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;

          .logText {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #999;
          }
        }

        .goodPrice {
          padding: 0 15px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 18px;

          .leftPrice {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 12px;

            .oldPrice {
              font-weight: 600;
            }

            .bigDiscount {
              padding: 5px 10px;
              background: #ff9933;
              color: white;
              border-radius: 5px;
              margin-left: 3px;
            }
          }

          .newPrice {
            font-size: 16px;
            color: #ff9933;
            font-weight: 600;
          }
        }

        .getPoint {
          margin: 10px auto;
          width: 80%;
          background-color: #f8ac59;
          color: white;
          border-radius: 20px;
          text-align: center;
          font-size: 12px;
          line-height: 20px;
        }
        .buyGood {
          font-size: 16px;
          color: white;
          font-weight: 700;
          width: 100%;
          text-align: center;
          background-color: #ff9933;
          padding: 5px 0;
          margin-top: 15px;
          border-radius: 5px;
        }
      }
    }
  }

  // 分页
  /deep/ .el-pagination {
    margin-top: 15px;
    display: flex;
    justify-content: center;
  }

  /deep/ .el-pager li.active {
    color: #fb8d20;
  }

  /deep/ .el-pager li {
    &:hover {
      color: #fb8d20;
    }
  }

  /deep/ .el-pagination button {
    &:hover {
      color: #fb8d20;
    }
  }
}
</style>
